import React, { FC } from 'react';
import * as styles from './styles';

interface LoadingProps {
    fullScreen?: boolean;
}

const Loading: FC<LoadingProps> = (props) => {
    const {
        fullScreen
    } = props;

    return (
        <div css={[styles.wrapper, fullScreen ? styles.fixed : null]}>
            Loading...
        </div>
    );
};

export default Loading;
