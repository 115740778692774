import { css, Interpolation, keyframes } from '@emotion/core';
import theme from 'static/themes/theme';

const pulse = keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
`;

export const logo = css`
    animation: ${pulse} 3s ease infinite;
`;

export const wrapper: Interpolation = {
    padding: 20,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    textAlign: 'center',
    img: {
        maxWidth: 100,
        margin: '0 auto',
        opacity: .3
    }
};

export const fixed: Interpolation = {
    position: 'fixed',
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: theme.colors.light,
    img: {
        maxWidth: 300
    }
};
