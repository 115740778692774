import { darken } from 'polished';

const brand = {
    primary: '#007bff',
    secondary: '#212529'
};

const colors = {
    primary: brand.primary,
    primary_hover: darken(0.075, brand.primary),
    secondary: brand.secondary,
    secondary_hover: darken(0.1, brand.secondary),
    mint: '#17B794',
    red: '#FD4949',
    blue: '#2699D6',
    yellow: '#E0B630',
    green: '#74AF55',
    dark: '#4C4C4C',
    medium: '#A2A2A2',
    white: '#FFFFFF',
    light: '#F2F2F2',
    footer: '#E2E2E2',
    border: '#E3E3E3',
    placeholder: '#c1bfd6'
};

const fontSizes = {
    normal: 16,
    h1: 48,
    h2: 33,
    h3: 24,
    h4: 19,
    bigger: 18,
    smaller: 14,
    tiny: 13
};

const theme = {
    colors,
    fontSizes,
    constants: {
        borderRadius: 3,
        spacer: 24,
        panelPadding: 24,
        menuWidth: 300,
        topBarHeight: 75,
        footerHeight: 70,
        iframeHeight: 500
    },
    breakpoints: [400, 600, 900, 1300, 1550]
};
export default theme;
