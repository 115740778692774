import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { css, Global } from '@emotion/core';
import theme from 'static/themes/theme';
import { ThemeProvider } from 'emotion-theming';
import { max } from 'utils/MediaQueryHelper';
import { SessionProvider } from 'contexts/sessionContext';

const globalStyle = css({
    '*, *:before, *:after': {
        boxSizing: 'border-box'
    },
    html: {
        fontSize: theme.fontSizes.normal,
        [max[3]]: {
            fontSize: theme.fontSizes.normal - 1
        }
    },
    '::selection': {
        color: theme.colors.white,
        backgroundColor: theme.colors.primary
    },
    body: {
        position: 'relative',
        margin: 0,
        padding: 0,
        overflowX: 'hidden',
        fontFamily: `Myriad Pro, Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif`,
        color: theme.colors.secondary,
        backgroundColor: theme.colors.light,
        WebkitFontSmoothing: 'antialiased',
        WebkitOverflowScrolling: 'touch',
        MozOsxFontSmoothing: 'grayscale'
    },
    '::-webkit-scrollbar': {
        width: 7,
        WebkitAppearance: 'none'
    },
    '::-webkit-scrollbar-thumb': {
        borderRadius: 4,
        backgroundColor: 'rgba(0,0,0,.5)',
        WebkitBoxShadow: '0 0 1px rgba(255,255,255,.5)'
    },
    '#root': {
        minHeight: '100vh',
        paddingBottom: theme.constants.footerHeight
    },
    code: {
        fontFamily: `source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace`
    },
    hr: {
        marginTop: theme.constants.spacer,
        marginBottom: theme.constants.spacer,
        border: 0,
        borderBottom: '1px solid ' + theme.colors.border,
        boxSizing: 'border-box'
    },
    'h1, h2, h3, h4, h5, h6, .h0, .h1, .h2, .h3, .h4, .h5, .h6': {
        fontFamily: `Montserrat, Myriad Pro, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif`,
        marginTop: 0,
        marginBottom: '1rem',
        lineHeight: '1.2',
        fontWeight: 700
    },
    'h1, .h1': {
        fontSize: theme.fontSizes.h1
    },
    'h2, .h2': {
        fontSize: theme.fontSizes.h2
    },
    'h3, .h3': {
        fontSize: theme.fontSizes.h3
    },
    'h4, .h4': {
        fontSize: theme.fontSizes.h4
    },
    'h5, .h5, h6, .h6': {
        fontWeight: 600,
        fontSize: theme.fontSizes.bigger
    },
    'h5, .h5, h6, .h6, .bigger': {
        fontSize: theme.fontSizes.bigger
    },
    '.smaller': {
        fontSize: theme.fontSizes.smaller
    },
    '.dark': {
        color: theme.colors.dark
    },
    '.primary': {
        color: theme.colors.primary
    },
    p: {
        marginTop: 0,
        marginBottom: '1rem'
    },
    'li, p': {
        lineHeight: 1.5
    },
    ul: {
        marginTop: 0
    },
    'b, strong': {
        fontWeight: 600
    },
    a: {
        textDecoration: 'none',
        color: 'inherit',
        cursor: 'pointer',
        '&:hover': {
            color: theme.colors.primary,
            svg: {
                '*': {
                    fill: theme.colors.primary
                }
            }
        }
    },
    img: {
        verticalAlign: 'middle'
    },
    '.react-datepicker-wrapper, .react-datepicker__input-container': {
        display: 'block !important'
    }
});

ReactDOM.render(
    <React.StrictMode>
        <Global styles={globalStyle} />
        <ThemeProvider theme={theme}>
            <SessionProvider>
                <App />
            </SessionProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
