import theme from 'static/themes/theme';

export const min = theme.breakpoints.map(
    (bp) => `@media (min-width: ${bp}px)`
);

export const max = theme.breakpoints.map(
    (bp) => `@media (max-width: ${bp}px)`
);

export const retina = '@media(-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)';