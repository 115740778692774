import React, { lazy, Suspense } from 'react';
import Loading from 'components/Loading';
import { Router } from '@reach/router';

// const BlankLayout = lazy(() => import('layouts/Blank'));
const LoginLayout = lazy(() => import('layouts/Login'));
const AdminLayout = lazy(() => import('layouts/Admin'));
const DefaultLayout = lazy(() => import('layouts/Default'));

let LoginWithUsername: any = null;
if (process.env.NODE_ENV === 'development') {
    LoginWithUsername = lazy(() => import('screens/General/LoginWithUsername'));
}

// Login layout
const LoginPage = lazy(() => import('screens/General/Login'));
const PasswordForgottenPage = lazy(() => import('screens/General/PasswordForgotten'));
const PasswordForgottenConfirmPage = lazy(() => import('screens/General/PasswordForgottenConfirm'));
const PasswordResetPage = lazy(() => import('screens/General/PasswordReset'));
const PasswordResetConfirmPage = lazy(() => import('screens/General/PasswordResetConfirm'));

// Admin layout
const DashboardPage = lazy(() => import('screens/Admin/Dashboard'));
const QuestionsPage = lazy(() => import('screens/Admin/Questions'));
const AddQuestionPage = lazy(() => import('screens/Admin/AddQuestion'));
const EditQuestionPage = lazy(() => import('screens/Admin/EditQuestion'));

// Default layout
const DashboardUserPage = lazy(() => import('screens/User/DashboardUser'));

const App = () => {
    return (
        <Suspense fallback={<Loading fullScreen />}>
            <Router>
                <LoginLayout path="account">
                    <LoginPage path="login" />
                    <LoginPage path="login/:redirectUrl" />
                    {
                        process.env.NODE_ENV === 'development' &&
                        <LoginWithUsername path="loginwithusername/:username" />
                    }
                    <PasswordForgottenPage path="wachtwoord-vergeten" />
                    <PasswordForgottenConfirmPage path="wachtwoord-vergeten-confirmatie" />
                    <PasswordResetPage path="wachtwoord-resetten" />
                    <PasswordResetConfirmPage path="wachtwoord-resetten-confirmatie" />
                </LoginLayout>

                <AdminLayout path="/admin">
                    <DashboardPage default path="/" />
                    <QuestionsPage path="vragen" />
                    <AddQuestionPage path="vragen/toevoegen" />
                    <EditQuestionPage path="vragen/:id/aanpassen" />
                </AdminLayout>

                <DefaultLayout path="/">
                    <DashboardUserPage default path="/" />
                </DefaultLayout>
            </Router>
        </Suspense>
    );
};

export default App;
